import React from "react";
import SlideHotspotsInfo from "./SlideHotspotsInfo";
import SlideImageWithHotspots from "./SlideImageWithHotspots";
import {MODE} from "./Slider";

export default ({
                    id, image, hotspots, userProductIds, mode,
                    onClickHotspot
                }) => {
    let content;

    switch (mode) {
        case MODE.IMAGE:
            content =
                <SlideImageWithHotspots
                    image={image}
                    hotspots={hotspots || []}
                    userProductIds={userProductIds}
                    handleClickHotspot={hotspotId => {
                        onClickHotspot(id, hotspotId)
                    }}
                />;
            break;
        case MODE.INFO:
            content =
                <SlideHotspotsInfo
                    hotspots={hotspots || []}
                    userProductIds={userProductIds}
                    handleClickHotspot={hotspotId => {
                        onClickHotspot(id, hotspotId)
                    }}
                />;
            break;
        default:
            break;
    }

    return (
        <div>
            {content}
        </div>
    );
};
