const API_URL =
    (process.env.NODE_ENV === 'test') ?
        'http://b2bdirectws.httdev.it//wp-json/wp/v2/promo' :
        (process.env.NODE_ENV === 'production') ?
            '//managewa.cafenoir.it/wp-json/wp/v2/promo' :
            'http://b2bdirectws.httdev.it//wp-json/wp/v2/promo';


const PROMO_BASE_URL = 'https://sfa.cafenoir.it/DesktopModules/SincroSFAws/WebAppal.aspx';

export default {
    getPromoList: async (userKey) => {

        const response = await fetch(API_URL);
        const json = await response.json();

        return json.map((adv) => {

            const name = adv.acf.famiglia_prodotto;
            const img = adv.featured_img_url;
            const url = `${PROMO_BASE_URL}?key=${userKey}&td=S&p=${name}`;

            return {name, img, url};
        });
    },
};
