import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import SlideImageStatus from "../components/SlideImageStatus";
import SlideImageActions from "../components/SlideImageActions";
import HotspotInfo from "../components/HotspotInfo";
import Hotspot from "../components/Hotspot";
import {MODE} from "../utility/SliderUtil";
import {useSwipeable} from "react-swipeable";

const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        '@media screen and (orientation: landscape)': {
            flexDirection: 'row',
        },
    },
    slider: {
        flexGrow: 1,
        '@media screen and (orientation: landscape)': {
            flexGrow: 0,
            flexBasis: '60%'
        },
        overflowY: 'scroll',

    },
    sliderImageContainer: {
        position: 'relative',
    },
    sliderImage: {
        width: '100%'
    },
    container1: {

    },
    container2: {
        '@media screen and (orientation: landscape)': {
            flexGrow: 0,
            flexBasis: '40%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
    }
}));

const TestPage = () => {
    const classes = useStyle();
    const isLandscape = useMediaQuery('screen and (orientation: landscape)');
    const sliders = [
        'https://managewa.cafenoir.it/wp-content/uploads/sliders/14/CN_Scarpe_adattamenti_web_app-32.jpg',
        'https://managewa.cafenoir.it/wp-content/uploads/sliders/5/CN_Denim_Capsule_adattamenti_web_app_ALTA-17.jpg',
        'https://b2bdirectws.httdev.it/wp-content/uploads/sliders/5/200721_cover_alta-DENIM.jpg',
        'https://managewa.cafenoir.it/wp-content/uploads/sliders/5/CN_Denim_Capsule_adattamenti_web_app_ALTA-2.jpg',
    ];

    /*
    const slidersData = sliders.map((imageSrc) =>{
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            console.log(`Scarico ${imageSrc} ${img.naturalWidth}x${img.naturalHeight}`);
        };
        return img;
    });
    */

    const slidersLength = sliders.length;
    const [actualSliderIdx, setActualSliderIdx] = useState(0);
    const handleNextSlider = () => {
        setActualSliderIdx(((actualSliderIdx) => ((actualSliderIdx + 1) % slidersLength)));
    };
    const handlerPrevSlider = () => {
        setActualSliderIdx(((actualSliderIdx) =>
            ((actualSliderIdx === 0) ?
                (slidersLength - 1) :
                (actualSliderIdx - 1))
        ));
    };

    const [mode, setMode] = useState(MODE.IMAGE);

    const handleActionClick = () => {
        setMode(prevMode => {
            if (prevMode === MODE.IMAGE) {
                return MODE.INFO;
            } else {
                return MODE.IMAGE;
            }
        })
    };

    const container1 = isLandscape ? null : (
        <SlideImageStatus
            title="Test"
            subTitle={`${actualSliderIdx + 1}/${slidersLength}`}
            onActionClick={handleActionClick}
        />
    );
    const container2 = isLandscape ? (
        <>
            <SlideImageStatus
                title="Test"
                subTitle={`${actualSliderIdx + 1}/${slidersLength}`}
                onActionClick={handleActionClick}
            />
            <SlideImageActions
                showMode={true}
                onActionClick={handleActionClick}
                onPrevActionClick={() => handlerPrevSlider()}
                onNextActionClick={() => handleNextSlider()}
                mode={mode}
            />
        </>
    ) : (
        <SlideImageActions
            showMode={true}
            onActionClick={handleActionClick}
            onPrevActionClick={() => handlerPrevSlider()}
            onNextActionClick={() => handleNextSlider()}
            mode={mode}
        />
    );

    const swipeableHandlers = useSwipeable({
        onSwipedLeft: handlerPrevSlider,
        onSwipedRight: handleNextSlider
    });

    const sliderContent = (mode === MODE.IMAGE) ? (
        <div className={classes.sliderImageContainer}>
            <img className={classes.sliderImage} src={sliders[actualSliderIdx]} alt=""/>
            <Hotspot
                status={true}
                top={10}
                left={2}
            />
            <Hotspot
                status={false}
                top={50}
                left={40}
            />
        </div>
    ) : (
        <div className={classes.sliderInfoContainer}>
            <HotspotInfo
                text="Test"
                desc="Descrizione"
                famiglia="SK100"
                status={true}
            />
            <HotspotInfo
                text="Test"
                desc="Descrizione"
                famiglia="SK100"
                status={false}
            />
            <HotspotInfo
                text="Test"
                desc="Descrizione"
                famiglia="SK100"
                status={true}
            />
            <HotspotInfo
                text="Test"
                desc="Descrizione"
                famiglia="SK100"
                status={false}
            />
            <HotspotInfo
                text="Test"
                desc="Descrizione"
                famiglia="SK100"
                status={true}
            />
            <HotspotInfo
                text="Test"
                desc="Descrizione"
                famiglia="SK100"
                status={false}
            />
        </div>
    );

    return (
        <div className={classes.root}>
            <div className={classes.container1}>
                {container1}
            </div>
            <div className={classes.slider} {...swipeableHandlers}>
                {sliderContent}
            </div>
            <div className={classes.container2}>
                {container2}
            </div>
        </div>
    );
};

export default TestPage;