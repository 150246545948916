import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Grid, Paper, Button, useMediaQuery} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heroImageBrand: {
        position: 'absolute',
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    heroImagePlaceholder: {
        width: '100%',
    },
    paper: {
        padding: '2rem',
        margin: '0 auto',
    },
    fields: {
        padding: '1rem 0'
    }
}));


const AccessPage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const { t } = useTranslation();

    const backgroundImage = matches ?
        '/images/light_login_placeholder_hero_desktop@2x.jpg':
        '/images/light_login_placeholder_hero@2x.jpg';

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item>
                    <Link to="/">
                        <img className={classes.heroImageBrand}
                             srcSet="/images/logo_black.png 2x"
                             alt="logo" />
                    </Link>

                    <img className={classes.heroImagePlaceholder}
                         alt="account"
                         srcSet={`${backgroundImage} 2x`}/>
                </Grid>
                <Grid container item>
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid item xs={12}>
                                <strong>{t('AccessPage.welcomeHeader')}</strong>
                            </Grid>
                            <Grid item xs={12} className={classes.fields}>
                                <Button component={Link} to="/register">{t('AccessPage.accountCreate')}</Button>
                            </Grid>
                            <Grid item xs={12} className={classes.fields}>
                                <Button component={Link} to="/login">{t('AccessPage.accountLogin')}</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

AccessPage.displayName = 'AccessPage';
export default AccessPage;
