import React from "react";
import {isUserAgentChoose, isUserLogged, useUser} from "../contexts/UserContext";
import {Redirect, Route} from 'react-router-dom';

export default ({children, ...rest}) => {
    const [user, ] = useUser();

    let redirect = null;

    if (!isUserLogged(user)) { // Se l'utente non e' loggato
        redirect = '/welcome';
    } else {
        if (!isUserAgentChoose(user)) { // Se l'utente e' agente, ma non ha fatto una scelta
            redirect = '/choose';
        }
    }

    return (
        <Route
            {...rest}
            render={({location}) => (redirect) ?
                <Redirect to={{
                    pathname: redirect,
                    state: {
                        from: location
                    }
                }}/> : (children)
            }/>
    )
}
