import React, {useState} from "react";
import {Button, Grid, TextField} from "@material-ui/core";
import PageHeader from "../layouts/PageHeader";
import PageFooter from "../layouts/PageFooter";
import {makeStyles} from "@material-ui/core/styles";
import {userRegister, useUser} from "../contexts/UserContext";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(themes => ({
    root: {
        flexGrow: 1,
        padding: '1rem 1rem',
    },
    fields: {
        padding: '1rem 0'
    }
}));

const registerReturnValueInitial = {
    error: false,
    message: ''
};

const RegisterPage = () => {
    const classes = useStyles();
    const [user, userAction] = useUser();
    const [registerValue, setRegisterValue] = useState({});
    const [registerReturnValue, setRegisterReturnValue] = useState(registerReturnValueInitial);
    const [step, setStep] = useState(0);

    const { t } = useTranslation();

    const handleInputChange = (event) => {
        event.preventDefault();

        const target = event.target;
        setRegisterValue(prevState => ({
            ...prevState,
            [target.name]: target.value
        }));

    };

    const handleSubmit = (event) => {
        event.preventDefault();

        userRegister(user, userAction, registerValue)
            .then(
                () => { // Se la registrazione ha avuto successo
                    setRegisterReturnValue({
                        error: false,
                        message: 'Registrazione Avvenuta, controlla la mail'
                    });
                },
                err => { // Se la registrazione e' fallita
                    setRegisterReturnValue({
                        error: true,
                        message: `${err.name} ${err.message}`
                    });
                }
            );


        //setRegisterValue({}); // Resetto la form
        setStep(0); // Resetto lo step
    };

    const steps = [
        [
            <TextField
                id="email"
                name="email"
                label={t('UserRegisterForm.form.username')}
                type="text"
                className={classes.fields}
                fullWidth
                value={registerValue.email || ''}
                onChange={handleInputChange}
            />,
            <TextField
                id="nome"
                name="nome"
                label={t('UserRegisterForm.form.name')}
                type="text"
                className={classes.fields}
                fullWidth
                value={registerValue.nome || ''}
                onChange={handleInputChange}
            />,
            <TextField
                id="cognome"
                name="cognome"
                label={t('UserRegisterForm.form.surname')}
                type="text"
                className={classes.fields}
                fullWidth
                value={registerValue.cognome || ''}
                onChange={handleInputChange}
            />,
            <TextField
                id="password"
                name="password"
                label={t('UserRegisterForm.form.password')}
                type="password"
                className={classes.fields}
                fullWidth
                value={registerValue.password || ''}
                onChange={handleInputChange}
            />
        ],
        [
            <TextField
                id="insegna"
                name="insegna"
                label={t('UserRegisterForm.form.insegna')}
                type="text"
                className={classes.fields}
                fullWidth
                value={registerValue.insegna || ''}
                onChange={handleInputChange}
            />,
            <TextField
                id="telefono"
                name="telefono"
                label={t('UserRegisterForm.form.phone')}
                type="text"
                className={classes.fields}
                fullWidth
                value={registerValue.telefono || ''}
                onChange={handleInputChange}
            />,
            <TextField
                id="nazione"
                name="nazione"
                label={t('UserRegisterForm.form.nazione')}
                type="text"
                className={classes.fields}
                fullWidth
                value={registerValue.nazione || ''}
                onChange={handleInputChange}
            />,
            <TextField
                id="provincia"
                name="provincia"
                label={t('UserRegisterForm.form.provincia')}
                type="text"
                className={classes.fields}
                fullWidth
                value={registerValue.provincia || ''}
                onChange={handleInputChange}
            />,
            <TextField
                id="localita"
                name="localita"
                label={t('UserRegisterForm.form.localita')}
                type="text"
                className={classes.fields}
                fullWidth
                value={registerValue.localita || ''}
                onChange={handleInputChange}
            />,
        ]
    ];

    const isFirstStep = (step === 0);
    const isLastStep = ((step + 1) === steps.length);

    const actionBtnNext = (
        <Button onClick={(isLastStep) ? handleSubmit : () => {
            setStep(step + 1)
        }}>
            {(isLastStep) ? t('UserRegisterForm.sendRequest') : t('UserRegisterForm.nextStep')}
        </Button>
    );
    const actionBtnBack = (isFirstStep) ? '' : (
        <Button onClick={() => {
            setStep(step - 1)
        }}>
            {t('UserRegisterForm.prevStep')}
        </Button>
    );

    const message = (registerReturnValue.error) ?
        (
            <h3>{registerReturnValue.message}</h3>
        ) :
        (
            <h3>{registerReturnValue.message}</h3>
        );

    return (
        <>
            <PageHeader/>
            <div className={classes.root}>
                <Grid container>
                    <Grid item>
                        <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container>
                                <h3>{t('UserRegisterForm.stepTitle', {stepCount: step + 1, stepNum: steps.length})}</h3>
                                {steps[step].map((input, idx) => {
                                    return (
                                        <Grid item xs={12} key={`${step}_${idx}`}>
                                            {input}
                                        </Grid>
                                    );
                                })}
                                {message}
                                {actionBtnNext}
                                {actionBtnBack}
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </div>
            <PageFooter/>
        </>
    );
};

RegisterPage.displayName = 'RegisterPage';
export default RegisterPage;
