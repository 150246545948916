import React from "react";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
        width: '100%',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2),
        margin: 'auto',
    },
    center: {
        textAlign: 'left',
    }
}));

export default ({text, desc, famiglia, status, onClickHotspot}) => {
    const classes = useStyles();
    const imgSrc = status ? 'lookbook_wishlist_on@2x.png' : 'lookbook_wishlist_off@2x.png';

    return (
        <div className={classes.root}>
            <ButtonBase onClick={() => onClickHotspot()} className={classes.button}>
                <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid container justify="flex-start" alignItems="center" item xs={9}>
                            <strong>{famiglia}</strong>
                        </Grid>
                        <Grid container justify="center" alignItems="center" item xs={3}>
                            <img
                                srcSet={`/images/${imgSrc} 2x`}
                                alt="like"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.center}>
                            <strong>{text}</strong>
                            <p>{desc}</p>
                        </Grid>
                    </Grid>
                </Paper>
            </ButtonBase>
        </div>
    );
};
