import React from "react";
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Carousel from 'nuka-carousel';
import ReactPlayer from 'react-player/youtube'
import {withTranslation} from "react-i18next";

const GET_VIDEOS = gql`
        query GetVideos {
          videos {
            edges {
              node {
                id
                title
                content
                url_video
              }
            }
          }
        }
`;

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1rem 1rem 2.5rem',
    },
    title: {
        padding: '1rem 0'
    }
}));

const usePlayerStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        paddingTop: '56.25%', //Player ratio: 100 / (1280 / 720)
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0,
    }
}));

const YoutubeVideoPlayer = ({url}) => {
    const classes = usePlayerStyles();

    return (
        <div className={classes.root}>
            <ReactPlayer
                className={classes.player}
                loop={false}
                light
                url={url}
                width='100%'
                height='100%'
            />
        </div>
    );
};

const VideosPreview = ({t}) =>  {
    const {loading, error, data} = useQuery(GET_VIDEOS);
    const classes = useStyles();

    if (loading) {
        return 'Caricamento';
    }

    if (error) {
        return `Error! ${error.message}`;
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item container justify="flex-start" xs={12}>
                    <strong className={classes.title}>{t('VideosPreview.headerMessage')}</strong>
                </Grid>
                <Grid item xs={12}>
                    <Carousel>
                        {data.videos.edges.map((videoEdge, idx) => (
                            <YoutubeVideoPlayer
                                key={idx}
                                url={videoEdge.node.url_video}
                            />
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </div>
    );
};

export default withTranslation()(VideosPreview);
