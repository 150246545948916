import React from "react";

import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '1rem 2rem'
    },
    p: {
        marginBottom: '0',
    },
    strong: {
        fontSize: '1.5rem'
    }
}));

const UserInfoLight = ({user, t}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item>
                    <p className={classes.p}>{t('UserInfoLight.WelcomeMessage')}</p>
                    <strong className={classes.strong}>{user.RagioneSociale}</strong>
                </Grid>
            </Grid>
        </div>
    )
};

export default withTranslation()(UserInfoLight);
