import React, {useEffect, useState} from "react";
import PageHeader from "../layouts/PageHeader";
import PageFooter from "../layouts/PageFooter";

import {useHistory} from "react-router-dom";
import {userLogin, useUser} from "../contexts/UserContext";
import UserLoginForm from "../components/UserLoginForm";
import {makeCancelable} from "../utility/PromiseUtil";


export default (props) => {
    const [loginErrorValue, setLoginErrorValue] = useState(null);
    const [user, userAction] = useUser();
    const history = useHistory();

    let loginCallPromise;
    useEffect(() => {
        return () => {
            if (loginCallPromise) {
                console.log('Cancel Promise');
                loginCallPromise.cancel();
            }
        }
    });

    const handleLogin = (loginValue) => {
        loginCallPromise = makeCancelable(
            userLogin(user, userAction, loginValue.username, loginValue.password)
        );

        loginCallPromise.promise
            .then(() => {
                history.push('/');
            }, err => {
                if (err.isCanceled) {
                    console.log('Cancel');
                } else {
                    setLoginErrorValue(`${err.name} ${err.message}`);
                }
            });
    };

    return (
        <>
            <PageHeader/>
            <UserLoginForm errorMessage={loginErrorValue} onFormSubmit={handleLogin}/>
            <PageFooter/>
        </>
    );
}
