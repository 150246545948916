import React from "react";

import {Button, Grid, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    heroImageBrand: {
        position: 'absolute',
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    heroImagePlaceholder: {
        width: '100%',
    },
    welcomeInfoWrapper: {
        backgroundColor: '#f7f7f7',
        padding: '3rem 0',
    },
    welcomeInfoContainer: {
        padding: '2rem 0',
        width: '75%',
        margin: '0 auto',
    },
    welcomeInfoItemContainer: {
        padding: '1rem 1rem',
    },
    welcomeInfoItemImg: {
        display: 'block',
        margin: '0 auto .7rem auto',
    },
    welcomeInfoItemP: {}
}));

export default () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const { t } = useTranslation();

    function renderWelcomeInfoItem (imageSrc, label) {
        return (
            <Grid item xs={6} sm={3} className={classes.welcomeInfoItemContainer}>
                <img className={classes.welcomeInfoItemImg} srcSet={`${imageSrc} 2x`} alt="video"/>
                <p className={classes.welcomeInfoItemP}>{label}</p>
            </Grid>
        );
    }

    const backgroundImage = matches ?
        '/images/landing_placeholder_video_desktop@2x.jpg' :
        '/images/landing_placeholder_video@2x.jpg';


    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item container xs={12}>
                    <Grid item className={classes.heroImageBrand}>
                        <Link to="/">
                            <img
                                 srcSet="/images/logo_black.png 2x"
                                 alt="logo"/>
                        </Link>
                    </Grid>
                    <img className={classes.heroImagePlaceholder}
                         alt="account"
                         srcSet={`${backgroundImage} 2x`}/>
                </Grid>

                <Grid item container>

                </Grid>

                {/* Welcome Container */}
                <Grid item xs={12} container justify="center" className={classes.welcomeInfoContainer}>
                    <Grid item xs={8}>
                        <strong>{t('WelcomePage.welcomeHeader1')}</strong>
                        <h2>{t('WelcomePage.welcomeHeader2')}</h2>
                        <p>
                            {t('WelcomePage.welcomeMessage')}
                        </p>
                        <Button component={Link} to='/access'>
                            {t('WelcomePage.welcomeButton')}
                        </Button>
                    </Grid>
                </Grid>

                {/* Welcome Info */}
                <Grid item container justify="center" alignItems="center" xs className={classes.welcomeInfoWrapper}>
                    <Grid item xs={12}>
                        <strong>{t('WelcomePage.info.title')}</strong>
                    </Grid>
                    {renderWelcomeInfoItem('/images/landing_ico_video@2x.png', t('WelcomePage.info.videoProducts'))}
                    {renderWelcomeInfoItem('/images/landing_ico_programma_acquisto@2x.png', t('WelcomePage.info.buyPrograms'))}
                    {renderWelcomeInfoItem('/images/landing_ico_novita@2x.png', t('WelcomePage.info.news'))}
                    {renderWelcomeInfoItem('/images/landing_ico_supportovendita@2x.png', t('WelcomePage.info.sellSupport'))}
                </Grid>

            </Grid>
        </div>
    );
};
