import React from "react";
import Hotspot from "./Hotspot";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    image: {
        maxWidth: '100%',
    }
}));

export default ({image, hotspots, userProductIds, handleClickHotspot}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid>
                <img className={classes.image} src={image} alt="slide"/>
                {hotspots.map(hotspot =>
                    <Hotspot
                        key={hotspot.id}
                        id={hotspot.id}
                        status={(userProductIds.indexOf(hotspot.id) !== -1)}
                        top={hotspot.top}
                        left={hotspot.left}
                        onClickHotspot={() => {
                            handleClickHotspot(hotspot.id)
                        }}
                    />
                )}
            </Grid>
        </div>
    );
};
