import {withRouter} from "react-router-dom";
import SlickSlider from "react-slick";
import React, {useRef, useState} from "react";
import Slide from "./Slide";
import SlideImageStatus from "./SlideImageStatus";
import SlideImageActions from "./SlideImageActions";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
    root: {},
    slider: {
        height: '100vh',
        maxHeight: 'calc(100vh - 170px)',
    }
}));

export const MODE = {
    IMAGE: 0,
    INFO: 1
};

const Slider = withRouter(({slider, userProductIds, handleClickHotspot, history}) => {
    const sliderInstance = useRef();
    const [slideIdx, setSlideIdx] = useState(0);
    const [mode, setMode] = useState(MODE.IMAGE);
    const classes = useStyle();

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIdx, newIdx) => {
            setSlideIdx(newIdx);
        }
    };

    function handleActionClick () {
        setMode(prevMode => {
            if (prevMode === MODE.IMAGE) {
                return MODE.INFO;
            } else {
                return MODE.IMAGE;
            }
        })
    }
    function handleSliderPrev () {
        sliderInstance.current.slickPrev();
    }
    function handleSliderNext () {
        sliderInstance.current.slickNext();

    }
    function handleCloseButton () {
        history.push('/catalog');
    }

    if (slider) { // Se ho trovato la slide
        return (
            <>
                <SlideImageStatus
                    title={slider.name}
                    subTitle={`${slideIdx + 1}/${slider.slides.length}`}
                    onActionClick={handleCloseButton}>
                </SlideImageStatus>
                <SlickSlider className={classes.slider} ref={slider => (sliderInstance.current = slider)} {...settings}>
                    {
                        slider.slides.map((slide) => {
                            return (
                                <Slide
                                    key={slide.id}
                                    id={slide.id}
                                    image={slide.link}
                                    hotspots={slide.hotspots}
                                    userProductIds={userProductIds}
                                    mode={mode}
                                    onClickHotspot={(slideId, hotspotId) => {
                                        handleClickHotspot(slider.id, slideId, hotspotId)
                                    }}
                                />
                            )
                        })
                    }
                </SlickSlider>
                <SlideImageActions
                    onPrevActionClick={handleSliderPrev}
                    onNextActionClick={handleSliderNext}
                    onActionClick={handleActionClick}
                    showMode={slider.slides[slideIdx].hotspots.length !== 0}
                    mode={mode}
                />
            </>
        );
    } else { // Se non ho trovato la slide
        return <h1>Nessuno slider trovato</h1>;
    }
});

export default Slider;
