const API_URL =
    (process.env.NODE_ENV === 'test') ?
        'http://b2bdirectws.httdev.it//wp-json/wp/v2/adv' :
        (process.env.NODE_ENV === 'production') ?
            '//managewa.cafenoir.it/wp-json/wp/v2/adv' :
            'http://b2bdirectws.httdev.it//wp-json/wp/v2/adv';


export default {
    getAdvList: async () => {
        const response = await fetch(`${API_URL}?per_page=100`);
        const json = await response.json();
        return json.map((adv) => ({
            name: '',
            url: '',
            img: adv.featured_img_url
        }));
    }
};
