import React, {useEffect, useState} from "react";
import PageHeader from "../layouts/PageHeader";
import {makeStyles} from "@material-ui/core/styles";
import WishListItem from "../components/WishListItem";
import {getUserId, isUserLogged, useUser} from "../contexts/UserContext";
import SliderAPI from "../utility/SliderAPI";
import CloseIcon from "@material-ui/icons/Close";
import {Snackbar, IconButton, Grid, ButtonBase} from "@material-ui/core";

const useStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        fontWeight: 'bold',
        fontSize: '2rem',
        paddingTop: '1rem',
    },
    wrapperPadding: {
        padding: '1rem 2.5rem'
    },
    p: {
        margin: 0,
    },
}));

function WishListPage () {
    const classes = useStyle();

    const [userProductIds, setUserProductIds] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState(null);
    const [user, ] = useUser();

    const handleNotifyClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNotifyMessage(null);
    };

    const handleClickHotspot = (sliderId, slideId, hotspotId) => {
        if (isUserLogged(user)) {
            SliderAPI
                .removeUserProductId(sliderId, slideId, getUserId(user), hotspotId)
                .then((data) => {
                    setNotifyMessage('Prodotto Rimosso dalla Wishlist');
                    setUserProductIds(
                        prevUserProductIds => prevUserProductIds.filter(
                            userProductId => userProductId.hotspot !== hotspotId)
                    )
                });
        } else {
            console.log('Not logged');
        }
    };

    const handleSendReport = () => {
        if (isUserLogged(user)) {
            SliderAPI
                .sendUserProductsReport(getUserId(user))
                .then(data => {
                    setNotifyMessage('Report Inviato');
                });
        }
    };

    useEffect(() => {
        if (isUserLogged(user)) {
            SliderAPI.getUserProductIds(getUserId(user))
                .then(userProductIds => {
                    setUserProductIds(userProductIds);
                    setLoaded(true);
                });
        }
    }, [user]);

    if (!loaded)
        return <p>Caricamento</p>;

    return (
        <div className={classes.root}>
            <PageHeader/>
            <Grid container>
                <Grid item container justify="space-between" xs={12} className={classes.wrapperPadding}>
                    <strong className={classes.title}>Wish List</strong>
                    {userProductIds.length ? (
                        <ButtonBase onClick={handleSendReport}>
                            <img alt="send-notify" srcSet="/images/ico_send_wishlist@2x.png 2x"/>
                        </ButtonBase>
                    ) : null}
                </Grid>
                {userProductIds.length ?
                    userProductIds.map(userProduct => (
                        <WishListItem
                            key={userProduct.hotspot}
                            sliderId={userProduct.slider_id}
                            sliderName={userProduct.slider_name}
                            slideId={userProduct.slide_id}
                            hotspotId={userProduct.hotspot}
                            hotspotName={userProduct.hotspot_name}
                            famiglia={userProduct.famiglia}
                            onWishListItemRemove={handleClickHotspot}
                        />
                    )) :
                    <Grid item className={classes.wrapperPadding}>
                        <p className={classes.p}>Nessun Prodotto aggiunto alla Wish List</p>
                    </Grid>
                }
            </Grid>
            <Snackbar
                open={notifyMessage !== null}
                autoHideDuration={3000}
                onClose={handleNotifyClose}
                message={notifyMessage}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleNotifyClose}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                }
            />
        </div>
    );
}

export default WishListPage;
