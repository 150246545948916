import React from "react";
import SliderPreview from "./SliderPreview";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Carousel from 'nuka-carousel';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#f7f7f7',
        padding: '1rem 1rem',
    },
    title: {
        padding: '1rem 0'
    }
}));

export default ({name, sliders, baseUrl}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item container justify="flex-start" xs={12}>
                    <strong className={classes.title}>{name}</strong>
                </Grid>
                <Grid item xs={12}>
                    <Carousel withoutControls wrapAround slidesToShow={2}>
                        {sliders.map(slider => {
                            return (
                                <SliderPreview
                                    key={slider.id}
                                    image={slider.slides[0].link}
                                    alt={slider.slides[0].name}
                                    desc={slider.name}
                                    link={`${baseUrl}/${slider.id}`}
                                />
                            );
                        })}
                    </Carousel>
                </Grid>
            </Grid>
        </div>
    );
};
