import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import {MODE} from "./Slider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    appBar: {
        padding: '.5rem 0'
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grow: {
        flexGrow: 1,
    },
}));


export default ({showMode, mode, onActionClick, onPrevActionClick, onNextActionClick}) => {
    const classes = useStyles();

    const catalogImage = (mode === MODE.IMAGE) ?
        '/images/ico_lookbook_foto_selected@2x.png' :
        '/images/ico_lookbook_foto_active@2x.png';

    const infoImage = (mode === MODE.INFO) ?
        '/images/ico_lookbook_lista_selected@2x.png' :
        '/images/ico_lookbook_lista_active@2x.png';

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color="secondary" className={classes.appBar}>
                <Toolbar>
                    <ButtonBase onClick={() => onPrevActionClick()}>
                        <img alt="left-arrow" srcSet={`/images/ico_swype_left@2x.png 2x`}/>
                    </ButtonBase>
                    <div className={classes.grow}/>
                    {showMode && (
                        <>
                            <ButtonBase onClick={() => onActionClick(MODE.IMAGE)}>
                                <img alt="action-show-catalog" srcSet={`${catalogImage} 2x`}/>
                            </ButtonBase>
                            <ButtonBase onClick={() => onActionClick(MODE.INFO)}>
                                <img alt="action-show-info" srcSet={`${infoImage} 2x`}/>
                            </ButtonBase>
                        </>
                    )}
                    <div className={classes.grow}/>
                    <ButtonBase onClick={() => onNextActionClick()}>
                        <img alt="right-arrow" srcSet={`/images/ico_swype_right@2x.png 2x`}/>
                    </ButtonBase>
                </Toolbar>
            </AppBar>
        </div>
    );
};
