import React, {Suspense} from 'react';
import './App.scss';

import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import AccessPage from "./pages/AccessPage";
import RegisterPage from "./pages/RegisterPage";
import WelcomePage from "./pages/WelcomePage";
import LoginPage from "./pages/LoginPage";

import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import CatalogPage from "./pages/CatalogPage";
import {UserProvider} from "./contexts/UserContext";
import PrivateRoute from "./routes/PrivateRoute";
import AccountPage from "./pages/AccountPage";
import WishListPage from "./pages/WishListPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ChoosePage from "./pages/ChoosePage";
import ConfirmPage from "./pages/ConfirmPage";
import {LanguageProvider} from "./contexts/LanguageContext";
import TestPage from "./pages/TestPage";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#ffffff',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: '12px',
            },
            text: {
                backgroundColor: 'black',
                color: 'white',
                fontWeight: 'bold',
                minHeight: '3rem',
                width: '100%',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: 'white',
                    color: 'black',
                }
            },
        }
    }
});


export default () => {
    return (
        <ThemeProvider theme={theme}>
            <UserProvider>
                <Suspense fallback="loading">
                    <LanguageProvider>
                        <Router>
                            <Switch>
                                <Route exact path="/test">
                                    <TestPage/>
                                </Route>
                                <Route exact path="/login">
                                    <LoginPage/>
                                </Route>
                                <Route exact path="/register">
                                    <RegisterPage/>
                                </Route>

                                <Route exact path="/access">
                                    <AccessPage/>
                                </Route>
                                <Route exact path="/confirm/:token">
                                    <ConfirmPage/>
                                </Route>
                                <Route exact path="/resetpassword">
                                    <ResetPasswordPage/>
                                </Route>
                                <Route exact path="/welcome">
                                    <WelcomePage/>
                                </Route>
                                <PrivateRoute exact path="/account">
                                    <AccountPage/>
                                </PrivateRoute>
                                <Route exact path="/choose">
                                    <ChoosePage/>
                                </Route>
                                <PrivateRoute path="/catalog">
                                    <CatalogPage/>
                                </PrivateRoute>
                                <PrivateRoute path="/wishlist">
                                    <WishListPage/>
                                </PrivateRoute>
                                <PrivateRoute exact path="/">
                                    <Redirect to="/catalog"/>
                                </PrivateRoute>
                            </Switch>
                        </Router>
                    </LanguageProvider>
                </Suspense>
            </UserProvider>
        </ThemeProvider>
    );
};
