import React from "react";
import {ButtonBase, Grid, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {download} from "../utility/downloadUtil";
import {withTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1rem 2rem',
        backgroundColor: '#f7f7f7',
    },

    title: {
        padding: '1rem 0'
    }
}));


const useItemStyles = makeStyles(theme => ({
    button: {
        margin: '.5rem 0',
        width: "100%",
    },
    paper: {
        padding: '1rem',
        width: '100%',
    },
}));

const CatalogItem = ({title, downloadLink}) => {
    const classes = useItemStyles();

    return (
        <ButtonBase className={classes.button} onClick={() => download(downloadLink)}>
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={10}>
                        {title}
                    </Grid>
                    <Grid item xs={2}>
                        <img alt="download" srcSet="/images/ico_download@2x.png 2x"/>
                    </Grid>
                </Grid>
            </Paper>
        </ButtonBase>
    )
};

const CatalogList = ({ t }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item container justify="flex-start" xs={12}>
                    <strong className={classes.title}>{t('CatalogList.headerMessage')}</strong>
                </Grid>
                <Grid item xs={12}>
                    <CatalogItem
                        title="Catalogo Trade FW20-21 – Shoes"
                        downloadLink="/downloads/CATALOGO_TRADE_FW20_2_SHOES.pdf"
                    />
                </Grid>
                <Grid item xs={12}>
                    <CatalogItem
                        title="Catalogo Trade FW20-21 – Accessories"
                        downloadLink="/downloads/CATALOGO_TRADE_FW20_21_ACCESSORIES.pdf"
                    />
                </Grid>
                <Grid item xs={12}>
                    <CatalogItem
                        title="Catalogo Trade FW20-21 – Apparel"
                        downloadLink="/downloads/CATALOGO_TRADE_FW20_21_APPAREL.pdf"
                    />
                </Grid>
            </Grid>
        </div>
    )
};

export default withTranslation()(CatalogList);
