import React from "react";
import styled from 'styled-components';

const HotspotImg = styled.img`
    position: absolute;
    top: ${props => props.top}%;
    left: ${props => props.left}%;
`;

export default ({status, top, left, onClickHotspot}) => {
    const imgSrc = status ? 'lookbook_wishlist_on@2x.png' : 'lookbook_wishlist_off@2x.png';
    return (
        <HotspotImg
            top={top}
            left={left}
            srcSet={`/images/${imgSrc} 2x`}
            onClick={() => onClickHotspot()}
        />
    );
}
