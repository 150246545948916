import React, {useEffect, useState} from "react";
import VideosPreview from "../components/VideosPreview";
import SlidersPreview from "../components/SlidersPreview";
import PageHeader from "../layouts/PageHeader";
import PageFooter from "../layouts/PageFooter";
import SliderAPI from "../utility/SliderAPI";
import Slider from "../components/Slider2";
//import Slider from "../components/Slider";
import {Route, Switch, useRouteMatch, withRouter} from "react-router-dom";
import {
    getAgentEmail,
    getAgentId,
    getUser,
    getUserId,
    getUserKey,
    getUserType,
    isUserLogged,
    useUser
} from "../contexts/UserContext";
import {getActualLanguage} from "../contexts/LanguageContext";

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import UserInfoLight from "../components/UserInfoLight";
import CatalogList from "../components/CatalogList";
import ImageCarousel from "../components/ImageCarousel";

import AdvAPI from '../utility/AdvAPI';
import PromoAPI from "../utility/PromoAPI";

import {CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";


const CatalogPage = () => {
    const {path} = useRouteMatch();
    const {t} = useTranslation();

    const [loaded, setLoaded] = useState(false);
    const [sliderCollections, setSliderCollections] = useState([]);
    const [userProductIds, setUserProductIds] = useState([]);
    const [advList, setAdvList] = useState([]);
    const [promoList, setPromoList] = useState([]);
    const [notifyMessage, setNotifyMessage] = useState(null);
    const [user, ] = useUser();

    const handleNotifyClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        //console.log(reason);
        setNotifyMessage(null);
    };

    const handleClickHotspot = (sliderId, slideId, hotspotId, famiglia) => {
        if (isUserLogged(user)) {
            const userId = getUserId(user);
            const agenteId = getAgentId(user);
            const agentEmail = getAgentEmail(user);

            if (userProductIds.indexOf(hotspotId) === -1) { // Aggiungo
                SliderAPI
                    .setUserProductId(sliderId, slideId, userId, hotspotId, famiglia, agenteId, agentEmail)
                    .then((data) => {
                        setNotifyMessage('Prodotto Aggiunto alla Wishlist');
                        setUserProductIds(
                            prevUserProductIds => [...prevUserProductIds, hotspotId]
                        );
                    });
            } else { // Rimuovo
                SliderAPI
                    .removeUserProductId(sliderId, slideId, userId, hotspotId, agenteId, agentEmail)
                    .then((data) => {
                        setNotifyMessage('Prodotto Rimosso dalla Wishlist');
                        setUserProductIds(
                            prevUserProductIds => prevUserProductIds.filter(
                                userProductId => userProductId !== hotspotId)
                        )
                    });
            }
        } else {
            console.log('Not logged');
        }
    };

    useEffect(() => {
        if (isUserLogged(user)) {
            const all = Promise.all([
                SliderAPI.getSliders(getUserType(user), getActualLanguage()),
                SliderAPI.getUserProductIds(getUserId(user)),
                AdvAPI.getAdvList(),
                PromoAPI.getPromoList(getUserKey(user)),
            ]);
            all.then(([sliders, userProductIds, advList, promoList]) => {
                setSliderCollections(sliders);
                setUserProductIds(userProductIds.map(userProductId => (userProductId.hotspot)));
                setAdvList(advList);
                setPromoList(promoList);
                setLoaded(true);
            });
        }
    }, [user]);

    const content = (loaded) ?
        (<div>
            <Switch>
                <Route exact path={path}>
                    <PageHeader/>
                    <UserInfoLight user={getUser(user)}/>
                    {sliderCollections.map((sliderCollection, idx) => (
                        <SlidersPreview
                            key={sliderCollection.name}
                            name={sliderCollection.name}
                            sliders={sliderCollection.sliders}
                            baseUrl={`${path}/slider/${idx}`}/>
                    ))}
                    <VideosPreview/>
                    <CatalogList/>
                    <ImageCarousel
                        name={t('CatalogPage.ImageCarousel.Promo.headerMessage')}
                        imageLinks={promoList}
                    />
                    <ImageCarousel
                        name={t('CatalogPage.ImageCarousel.ADV.headerMessage')}
                        imageLinks={advList}
                    />
                    <PageFooter/>
                </Route>
                <Route
                    exact
                    path={`${path}/slider/:sliderCollectionId/:sliderId`}
                    render={routeProps => {
                        const {sliderCollectionId ,sliderId} = routeProps.match.params;
                        const sliderCollection = sliderCollections[parseInt(sliderCollectionId)];
                        const slider = sliderCollection.sliders.find(slider => slider.id === parseInt(sliderId));
                        return (
                            <>
                                <Slider
                                    slider={slider}
                                    userProductIds={userProductIds}
                                    handleClickHotspot={handleClickHotspot}
                                />
                                <Snackbar
                                    open={notifyMessage !== null}
                                    autoHideDuration={3000}
                                    onClose={handleNotifyClose}
                                    message={notifyMessage}
                                    action={
                                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleNotifyClose}>
                                            <CloseIcon fontSize="small"/>
                                        </IconButton>
                                    }
                                />
                            </>
                        );
                    }}
                />
            </Switch>
        </div>) :
        (
            <div>
                <CircularProgress/>
            </div>
        );

    return (
        <section>
            {content}
        </section>
    );
};

export default withRouter(CatalogPage);
