import React from "react";
import HotspotInfo from "./HotspotInfo";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1rem'
    }
}));

export default ({hotspots, userProductIds, handleClickHotspot}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {hotspots.map(hotspot =>
                    <Grid container item key={hotspot.id}>
                        <HotspotInfo
                            status={(userProductIds.indexOf(hotspot.id) !== -1)}
                            text={hotspot.text}
                            famiglia={hotspot.famiglia}
                            desc={hotspot.desc}
                            onClickHotspot={() => {
                                handleClickHotspot(hotspot.id)
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
