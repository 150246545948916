import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
    },
    appBar: {
        padding: '.5rem 0',
    }
}));

export default ({onActionClick, title, subTitle}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color="secondary" className={classes.appBar}>
                <Toolbar>
                    <Grid container justify="center">
                        <Grid item>
                            <strong>
                                {title}
                            </strong>
                            <p>
                                {subTitle}
                            </p>
                        </Grid>
                    </Grid>
                    <div className={classes.grow}/>
                    <ButtonBase onClick={() => onActionClick()}>
                        <img alt="close" srcSet={'/images/ico_chiudi@2x.png 2x'}/>
                    </ButtonBase>
                </Toolbar>
            </AppBar>
        </div>
    );
}
