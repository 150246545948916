import React from "react";
import {ButtonBase, Grid, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '1rem 2.5rem',
    },
    paper: {
        padding: '1rem',
    }
}));

export default ({
                    sliderId, sliderName, slideId, hotspotId, hotspotName, famiglia,
                    onWishListItemRemove
                }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container direction="column">
                        <Grid item>
                            <strong>{sliderName}</strong>
                        </Grid>
                        <Grid item container>
                            <Grid item xs={8}>
                                <p>{famiglia}</p>
                                <p>{hotspotName}</p>
                            </Grid>
                            <Grid item xs={4} container alignItems="center" justify="center">
                                <Grid item>
                                    <ButtonBase onClick={() => onWishListItemRemove(sliderId, slideId, hotspotId)}>
                                        <img alt="remove" srcSet="/images/ico_chiudi@2x.png 2x"/>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Grid>
    );
};
