import i18next from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // Setta la lingua di base commentato per far effettuare il detection */
        /* lng: "en", */

        // Setto le lingue di ripiego
        fallbackLng: {
            'en-US': ['en'],
            'it-IT': ['it'],
            default: ['en']
        },

        // Setto quale sara' il path delle traduzioni in questo caso la cartella locales
        // es: /locales/it.json
        // es: /locales/de.json
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },

        // Per istruire React su come riconoscere la lingua
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

        // In questo modo anche se la lingua del browser e' 'it_IT' si cerca la traduzione per 'it'
        load: 'languageOnly',

        // Per caricare prima le lingue e poi React
        react: {
            wait: true
        },

        // Per degli ulteriori messaggi di debug dalla console
        debug: process.env.NODE_ENV === 'development',

    });
