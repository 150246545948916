import React from "react";

import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {getAgentCustomers, getUser, userMock, useUser} from "../contexts/UserContext";
import ChooseCustomerItem from "../components/ChooseCustomerItem";
import PageHeader from "../layouts/PageHeader";
import PageFooter from "../layouts/PageFooter";
import ChooseCustomerActual from "../components/ChooseCustomerActual";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '1rem 2rem',
    },
    title: {
        padding: '1rem 0'
    }
}));

const ChoosePage = (props) => {
    const classes = useStyles();
    const [user, userAction] = useUser();

    const handleCustomerItemClick = (codice) => {
        userMock(user, userAction, codice);
    }

    const customers = (user.userData === null) ?
        <p>Nessun Cliente Trovato</p> :
        <>
            {
                _.sortBy(getAgentCustomers(user), (customer) => {
                    return customer.RagioneSociale
                })
                    .map(customer => (
                        <Grid key={customer.Codice} item xs={12}>
                            <ChooseCustomerItem
                                codice={customer.Codice}
                                ragioneSociale={customer.RagioneSociale}
                                onCustomerItemClick={handleCustomerItemClick}
                            />
                        </Grid>
                    ))
            }
        </>;

    return (
        <>
            <PageHeader/>
            <div className={classes.root}>
                <Grid container>
                    <Grid item container justify="flex-start" xs={12}>
                        <strong className={classes.title}>Clienti</strong>
                    </Grid>
                    <ChooseCustomerActual
                        user={getUser(user)}
                    />
                    {customers}
                </Grid>
            </div>
            <PageFooter/>
        </>
    );
};

export default ChoosePage;
