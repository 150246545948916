import React from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import {Toolbar, AppBar} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import {Person, People, Favorite} from "@material-ui/icons";

import {Link} from 'react-router-dom';
import {isUserAgent, useUser} from "../contexts/UserContext";
import LanguageIcon from "../components/LanguageIcon";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
    },
}));


export default function PrimarySearchAppBar () {
    const classes = useStyles();
    const [user, ] = useUser();


    const mockUser = isUserAgent(user) ? (
        <IconButton
            component={Link}
            to="/choose"
            color="inherit">
            <People/>
        </IconButton>
    ) : null;

    const wishList = (
            <div className={classes.sectionMobile}>
                <IconButton
                    component={Link}
                    to="/wishlist"
                    color="inherit">
                    <Favorite/>
                </IconButton>
            </div>
        );

    return (
        <div className={classes.grow}>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton
                        component={Link}
                        to="/account"
                        color="inherit">
                        <Person/>
                    </IconButton>
                    {mockUser}
                    <div className={classes.grow}/>
                    <Link to="/">
                        <img
                            className="img-fluid"
                            srcSet={"/images/logo.png 2x"}
                            alt="logo"/>
                    </Link>
                    <div className={classes.grow}/>
                    <LanguageIcon className={classes.sectionMobile}/>
                    {wishList}
                </Toolbar>
            </AppBar>
        </div>
    );
}
