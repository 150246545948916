import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";

import PageHeader from "../layouts/PageHeader";
import PageFooter from "../layouts/PageFooter";
import {userConfirm, useUser} from "../contexts/UserContext";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

const confirmReturnValueInitial = {
    error: false,
    message: ''
};

const ConfirmPage = () => {
    const classes = useStyles();
    const [user, userAction] = useUser();
    const [confirmReturnValue, setConfirmReturnValue] = useState(confirmReturnValueInitial);
    const {token} = useParams();

    useEffect(() => {
        userConfirm(user, userAction, token)
            .then(
                (data) => {
                    console.log(data);
                    setConfirmReturnValue({
                        error: false,
                        message: `Email ${data.email} confermata correttamente.`,
                    });
                },
                err => {
                    console.log(err);
                    setConfirmReturnValue({
                        error: true,
                        message: `${err.name} ${err.message}`,
                    });
                }
            );
    }, [token, user, userAction]);

    const message = (confirmReturnValue.error) ?
        (
            <h3>{confirmReturnValue.message}</h3>
        ) :
        (
            <h3>{confirmReturnValue.message}</h3>
        );

    return (
        <>
            <PageHeader/>
            <div className={classes.root}>
                <Grid container>
                    <Grid item>
                        {message}
                    </Grid>
                </Grid>
            </div>
            <PageFooter/>
        </>
    );
};

ConfirmPage.displayName = 'ConfirmPage';
export default ConfirmPage;
