import React from "react";
import {getUser, userLogout, useUser} from "../contexts/UserContext";
import UserInfo from "../components/UserInfo";
import PageHeader from "../layouts/PageHeader";
import PageFooter from "../layouts/PageFooter";

const AccountPage = () => {
    const [user, userAction] = useUser();

    return (
        <>
            <PageHeader/>
            <UserInfo user={getUser(user)} onLogout={() => userLogout(user, userAction)}/>
            <PageFooter/>
        </>
    );
};

AccountPage.displayName = 'AccountPage';
export default AccountPage;
