import {Button, ButtonBase, Grid, useMediaQuery} from "@material-ui/core";
import Carousel from "nuka-carousel";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {download} from "../utility/downloadUtil";
import {useUser, isUserAppUser} from "../contexts/UserContext";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1rem 2rem',
    },
    title: {
        padding: '1rem 0'
    }
}));


const useItemStyles = makeStyles(theme => ({
    image: {
        maxWidth: '100%',
    },
}));

const PromoCarouselItem = ({imageLink, imageName, imageUrl}) => {
    const classes = useItemStyles();
    const [user, ] = useUser();
    const {t} = useTranslation();

    return (
        <>
            <ButtonBase onClick={() => download(imageLink)}>
                <img alt="promo" className={classes.image} src={imageLink}/>
            </ButtonBase>
            {(imageName && !isUserAppUser(user)) && (
                <Button target="_blank" href={imageUrl} rel="noopener noreferrer">{t('ImageCarousel.buttonMessage', {name: imageName})}</Button>
            )}
        </>
    )
};

const ImageCarousel = ({name, imageLinks}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item container justify="flex-start" xs={12}>
                    <strong className={classes.title}>{name}</strong>
                </Grid>
                <Grid item xs={12}>
                    <Carousel autoplay withoutControls wrapAround slidesToShow={(matches) ? 2 : 1}>
                        {imageLinks.map((imageLink, idx) => (
                            <PromoCarouselItem
                                key={idx}
                                imageLink={imageLink.img}
                                imageName={imageLink.name}
                                imageUrl={imageLink.url}
                            />
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </div>
    );
};

export default ImageCarousel;
