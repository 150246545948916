import {Link} from "react-router-dom";
import {ButtonBase} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    button: {
        margin: '0 1rem',
    },
    sliderImage: {
        maxWidth: '100%',
        borderRadius: '25px',
    }
}));

export default ({image, alt, desc, link}) => {
    const classes = useStyles();

    return (
        <ButtonBase className={classes.button} component={Link} to={link}>
            <img alt={alt} className={classes.sliderImage} src={image}/>
        </ButtonBase>
    );
}
