import {getLanguages, setActualLanguage, useLanguage} from "../contexts/LanguageContext";
import {Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {Language} from "@material-ui/icons";
import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";

const LanguageDialog = ({open, onClose, onLanguageSelected}) => {
    const languages = getLanguages();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Scegli la tua lingua</DialogTitle>
            <List>
                {languages.map(language => (
                    <ListItem key={language} button onClick={() => onLanguageSelected(language)}>
                        <ListItemIcon>
                            <Language/>
                        </ListItemIcon>
                        <ListItemText primary={language}/>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    )
};

const LanguageIcon = (props) => {
    const [languageModalOpen, setLanguageModalOpen] = useState(false);
    const [language, languageAction] = useLanguage();

    const handleLanguageModalOpen = (event) => {
        event.preventDefault();
        setLanguageModalOpen(true);
    };

    const handleLanguageModalClose = (event) => {
        event.preventDefault();
        setLanguageModalOpen(false);
    };

    const handleLanguageModalSelected = (newLanguage) => {
        setLanguageModalOpen(false);
        setActualLanguage(language, languageAction, newLanguage);
    };

    return (
        <div {...props}>
            <LanguageDialog
                open={languageModalOpen}
                onClose={handleLanguageModalClose}
                onLanguageSelected={handleLanguageModalSelected}/>
            <IconButton
                onClick={handleLanguageModalOpen}
                color="inherit">
                <Language/>
            </IconButton>
        </div>
    )
};

export default LanguageIcon;
