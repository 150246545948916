import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    }
}));

export default ({user}) => {
    const classes = useStyles();

    if (!user) {
        return '';
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item>
                    <strong>Cliente Attuale</strong>
                    <p>
                        {user.Codice}<br/>
                        {user.RagioneSociale}<br/>
                        {user.PartitaIVA}<br/>
                        {user.Email}<br/>
                    </p>
                </Grid>
            </Grid>
        </div>
    )
};
